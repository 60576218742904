<template>
    <div>
        <div>
            <h6 v-if="isFido" class="text-center">Follow on screen steps to verify your passkey</h6>
            <h6 v-else class="text-center">Please enter verification token</h6>
        </div>
        <div class="mb-2 mt-2">
            <label class="form-label" for="username">{{$t(serviceInputNames[props.state.currentProvider] ?? 'Username')}}</label>
            <input type="text" name="username" v-model="props.state.username" id="username" disabled class="form-control"  />
        </div>
        <div class="mt-2" v-if="!isFido">
            <label class="form-label" for="token">{{$t('Token')}}</label>
            <input v-on:keyup.enter="verifyToken(null)" type="text" name="token" v-model="validatedToken" id="token" class="form-control" :class="{ 'is-invalid' : errors?.token }" autocomplete="off" required>
            <span class="invalid-feedback mt-0" v-if="errors?.token">{{ errors.token }}</span>
        </div>
        <div class="form-check pt-2" v-if="props.state.isAuthenticated">
            <input class="form-check-input" type="checkbox" v-model="rememberMe" name="rememberMe" id="rememberMe">
            <label class="form-check-label" for="rememberMe">
                {{$t('Remember this browser')}}
            </label>
        </div>

        <ErrorComponent v-if="errors" :errors="errors" :capsLockOn="capsLockOn" :key="state.updated" :exclude="['token']" /> 

        <div class="form-group pt-2 mb-2">
            <div class="col">
                <button v-if="isFido" type="button" class="btn btn-o365-login" @click="verifyFido">{{$t('Verify')}}</button>
                <button v-else type="button" class="btn btn-o365-login" @click="verifyToken(null)">{{$t('Verify')}}</button>
            </div>            
        </div>  
        <div class="d-flex justify-content-between align-items-center my-3">
            <a type="button" @click="resetCurrentState">{{$t('Back')}}</a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, inject, computed } from 'vue';
    import { createRequest, serviceNames, hasQueryParam, coerceToBase64Url, serviceInputNames } from 'o365.modules.Login.shared.js';
    import ErrorComponent from 'o365.vue.components.Login.Errors.vue';

    onMounted(async () => {        
        if(props.state.rememberMeDefaultValue){
            rememberMe.value = props.state.rememberMeDefaultValue;
        }
        isBusy(false);
    });

    const setErrors = inject('setErrors') as Function;
    const resetState = inject('resetState') as Function;
    const updateState = inject('updateState') as Function;
    const isBusy = inject('isBusy') as Function;
    
    const props = defineProps({
        state: { type: Object, required: true },
        errors: { type: Object, required: false, default: {} }, 
        capsLockOn: { type: Boolean, required: false, default: false }
    });

    const isFido = ref(props.state.currentProvider == 'fido2');
    const token = ref('');
    const rememberMe = ref(false);

    const validatedToken = computed({
        get() {
            return token.value;
        },
        set(value) {
            token.value = value;
            setErrors(null);
        }
    });

    async function resetCurrentState(){
        if(hasQueryParam('smsToken')){
            //await createRequest("/api/login/mfa", { back: true });
            let url = new URL(window.location.href);
            url.searchParams.delete('smsToken');
            window.history.pushState({}, document.title, url);
        }

        resetState();
    }

    async function verifyFido() {
        isBusy(true);
        try {

            var optionsResponce = await createRequest('/nt/api/fido2/assertOptions', { username: props.state.username });
            var options = await optionsResponce.json();
            if(!optionsResponce.ok){
                setErrors(options.errors);
                return;
            }
            const challenge = options.challenge.replace(/-/g, "+").replace(/_/g, "/");
            options.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));
            
            options.allowCredentials.forEach(function (listItem) {
                var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
                listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
            });

            let assertion = await navigator.credentials.get({ publicKey: options });

            await verifyToken(assertion);
            // await verifyAssertion(credential);            
            // authenticated();
        } catch (err) {
            setErrors(err.message);
        } finally {
            isBusy(false);
        }
    }

     async function getAssertion(assertedCredential) {
        // Move data into Arrays incase it is super long
        let authData = new Uint8Array(assertedCredential.response.authenticatorData);
        let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);
        return {
            id: assertedCredential.id,
            rawId: coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
                authenticatorData: coerceToBase64Url(authData),
                clientDataJson: coerceToBase64Url(clientDataJSON),
                signature: coerceToBase64Url(sig)
            }
        };
    }

    async function verifyToken(assertedCredential){
        if(!isFido.value && token.value.length < 4){
            setErrors({ token: 'Token is too short!'});
            return;
        }
        isBusy(true);
        var data = { 
            rememberMe: rememberMe.value
        };
        if(!isFido.value){
            data['token'] = token.value;
        } else {
            data['assertion'] = await getAssertion(assertedCredential);
        }
        var route = '/api/login/mfa';
        var response = await createRequest(route, data);
        if(response.ok){
            var json = await response.json();
            props.state.multiFactorState = json.multiFactorState;
            updateState(json.state);            
        } else if(response.status == 400){
            isBusy(false);
            var json = await response.json();
            console.log('Updating errors: ', json.errors);
            updateState(json.state);
            setErrors(json.errors);
        } else {
            isBusy(false);
            const text = await response.text();
            setErrors({error: text});
        }
    }
</script>